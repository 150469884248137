/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-11 14:29:25
 * @LastEditors: hutian
 * @LastEditTime: 2021-04-15 16:49:19
 */
export const columns = [
{
    title: '订单编号',
    dataIndex: 'orderCode',
    key: 'orderCode',
    width:200,
    align:'center',
    ellipsis:true
},
{
    title: '客户名称',
    dataIndex: 'dealerName',
    key: 'dealerName',
    width:150,
    align:'center',
    ellipsis:true
},
{
    title: '合同类型',
    dataIndex: 'contractType',
    key: 'contractType',
    // width:150,
    align:'center',
    ellipsis:true
},
{
    title: '订单类型',
    dataIndex: 'orderType',
    key: 'orderType',
    // width:150,
    align:'center',
    ellipsis:true
},
{
    title: '下单方式',
    dataIndex: 'sourceType',
    key: 'sourceType',
    // width:150,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'sourceType'}
},
{
    title: '订单金额(元)',
    dataIndex: 'payAmount',
    key: 'payAmount',
    // width:150,
    align:'center',
    ellipsis:true
},
{
    title: '订单状态',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    // width:150,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'orderStatus'}
},
{
    title: '下单时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width:150,
    align:'center',
    ellipsis:true
},
]
